* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Titillium Web", sans-serif !important;
}
body {
  font-family: "Titillium Web", sans-serif !important;
  font-size: 14px !important;
  line-height: 1.42857143 !important;
  color: #333 !important;
  background-color: #fff !important;
}
body {
  font-size: 14px;
  line-height: 1;
  font-family: Open Sans;
  color: #808080;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin: 0;
  color: #183650;
  font-weight: 300;
  font-family: Titillium Web;
}
/*
h1:before,
.h1:before,
h2:before,
.h2:before,
h3:before,
.h3:before,
h4:before,
.h4:before,
h5:before,
.h5:before,
h6:before,
.h6:before {
  content: "";
  border-bottom: 3px solid #0089fd;
  width: 50px;
  display: block;
  margin-bottom: 33px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
}
*/
h1.white_stripe:before,
.h1.white_stripe:before,
h2.white_stripe:before,
.h2.white_stripe:before,
h3.white_stripe:before,
.h3.white_stripe:before,
h4.white_stripe:before,
.h4.white_stripe:before,
h5.white_stripe:before,
.h5.white_stripe:before,
h6.white_stripe:before,
.h6.white_stripe:before {
  border-bottom-color: #fff;
}

h1,
.h1 {
  font-size: 42px;
  margin: 0 0 33px;
}

h2,
.h2,
.comment-reply-title {
  font-size: 36px !important;
  margin: 0 0 32px !important;
  line-height: 42px !important;
}
h2:before,
.h2:before,
.comment-reply-title:before {
  margin-bottom: 35px;
}

h3,
.h3 {
  font-size: 30px;
  margin: 0 0 33px;
}
h3:before,
.h3:before {
  margin-bottom: 31px;
}

h4,
.h4 {
  font-size: 24px;
  margin: 0 0 21px;
}
h4:before,
.h4:before {
  margin-bottom: 26px;
}

h5,
.h5 {
  font-size: 18px;
  margin: 0 0 25px;
}
h5:before,
.h5:before {
  margin-bottom: 27px;
}

h6,
.h6 {
  font-size: 14px !important;
  margin: 0 0 19px !important;
  letter-spacing: 0.04em !important;
}
h6:before,
.h6:before {
  margin-bottom: 22px;
}

p {
  line-height: 26px;
  margin: 0 0 26px;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.clearfix:before,
.clearfix:after,
.dl-horizontal dd:before,
.dl-horizontal dd:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after,
.form-horizontal .form-group:before,
.form-horizontal .form-group:after,
.btn-toolbar:before,
.btn-toolbar:after,
.btn-group-vertical > .btn-group:before,
.btn-group-vertical > .btn-group:after,
.nav:before,
.nav:after,
.navbar:before,
.navbar:after,
.navbar-header:before,
.navbar-header:after,
.navbar-collapse:before,
.navbar-collapse:after,
.pager:before,
.pager:after,
.panel-body:before,
.panel-body:after,
.modal-footer:before,
.modal-footer:after {
  content: " ";
  display: table;
}

.image-container {
  height: 400px;
  /*margin-top: 100px;*/
  margin-bottom: 75px;
}
.carousel-item img {
  height: 400px;
  object-fit: cover;
}
.home-box .home-thumb img {
  width: 100%;
}
.home-box .home-content {
  padding: 25px 15px 0;
}
.home-box h6 {
  text-transform: uppercase;
  font-weight: 600;
}

#footer {
  position: relative;
  background: #32364e;
  color: #fff;
}

#footer .footer_widgets {
  position: relative;
  padding: 30px 0 0;
}

#footer .footer_widgets .row {
  display: block;
}
#footer .copyright_row {
  position: relative;
}

#footer .copyright_row .copyright_row_wr {
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  padding: 20px 0 12px;
}
#footer .copyright_row .copyright_row_wr .copyright {
  font-size: 13px;
  line-height: 15px;
  color: rgba(255, 255, 255, 0.7);
  width: 50%;
  padding: 9px 0 17px;
}

.page_title {
  background-repeat: repeat;
  background-position: 50% 0;
  padding: 80px 0;
}
.page_title h1 {
  color: #fff;
  margin: 0;
}
.page_title h1:before {
  display: none;
}
.item-page h3 {
  margin: 1.75rem 0;
}

.wpb_text_column ol,
.comment-body ol {
  list-style: none;
  padding: 0;
  margin: 0 0 26px;
  counter-reset: item;
}
.wpb_text_column ol li,
.comment-body ol li {
  position: relative;
  margin: 0 0 12px;
  padding-left: 20px;
  line-height: 26px;
}
.wpb_text_column ol li:before,
.comment-body ol li:before {
  position: absolute;
  left: 0;
  top: 0;
  content: counter(item) ".";
  counter-increment: item;
  color: #34ccff;
}
.wpb_text_column ol li ol,
.wpb_text_column ol li ul,
.comment-body ol li ol,
.comment-body ol li ul {
  margin-top: 12px;
}
.wpb_text_column ul,
.comment-body ul {
  list-style: none;
  padding: 0;
  margin: 0 0 26px;
}
.wpb_text_column ul li,
.comment-body ul li {
  position: relative;
  margin: 0 0 12px;
  line-height: 26px;
  padding-left: 14px;
}
.wpb_text_column ul li:before,
.comment-body ul li:before {
  content: "•";
  position: absolute;
  left: 0;
  top: -1px;
  font-size: 18px;
  color: #34ccff;
}
.wpb_text_column ul li ol,
.wpb_text_column ul li ul,
.comment-body ul li ol,
.comment-body ul li ul {
  margin-top: 12px;
}
.wpb_text_column ul.style_2 li,
.comment-body ul.style_2 li {
  padding-left: 25px;
}
.wpb_text_column ul.style_2 li:before,
.comment-body ul.style_2 li:before {
  content: "";
  top: 12px;
  width: 10px;
  height: 1px;
  background: #34ccff;
}
.wpb_text_column ul.style_3 li,
.comment-body ul.style_3 li {
  padding-left: 30px;
}
.wpb_text_column ul.style_3 li:before,
.comment-body ul.style_3 li:before {
  content: "\e93f";
  font-family: "stm";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 8px;
  left: 0;
  font-size: 10px;
}
.wpb_text_column table,
.comment-body table {
  width: 100%;
  margin: 0 0 40px;
}
.wpb_text_column table td,
.wpb_text_column table th,
.comment-body table td,
.comment-body table th {
  padding: 10px;
  border: 1px solid #e6e6e6;
}
.wpb_text_column dl,
.comment-body dl {
  margin: 0 0 40px;
}
.wpb_text_column dl dd,
.comment-body dl dd {
  margin: 0 0 10px;
}
.wpb_text_column embed,
.wpb_text_column iframe,
.comment-body embed,
.comment-body iframe {
  max-width: 100%;
}

.read_more {
  position: relative;
  text-decoration: none !important;
}
.read_more em {
  display: inline-block;
  vertical-align: middle;
  font-style: normal;
  border-bottom: 1px solid transparent;
  line-height: 1;
}
.read_more span {
  display: inline-block;
  vertical-align: middle;
  font-size: 13px;
  line-height: 13px;
  border-bottom: 1px solid transparent;
  padding-left: 5px;
  padding-top: 1px;
}
.read_more:hover em {
  border-bottom-color: #34ccff;
}

#content {
  position: relative;
  z-index: 20;
  padding: 40px 0;
  overflow: hidden;
}

.fc_outer {
  float: left;
  width: 40%;
  padding: 0 !important;
  border: 0 !important;
  background: none !important;
}
.fc_after {
  float: right;
  width: 50%;
}
.fc_line.fc_msg {
  margin-bottom: 15px;
}
div.fc_line label.fc_left {
  text-align: left;
  width: 100% !important;
}
div.fc_line label {
  min-height: 20px;
  font-weight: 400;
}
div.fc_send input.fc_button {
  font-weight: 400;
  border-radius: 6px;
}
@media screen and (max-width: 640px) {
  #main #inner-content {
    margin-top: 0;
  }
  #inner-banner {
    top: 0;
  }
  .fc_after,
  .fc_outer {
    width: 100%;
  }
}
