* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /*height: 100px;*/
  /*background-color: #32364e;
  color: #ffffff;*/
  background-color: #ffffff;
  color: #32364e;
  font-family: "Titillium Web", sans-serif;
}

/*
.logo {
  font-size: 30px;
}
*/
.nav-links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  width: 75%;
}
.nav-item {
  color: #32364e;
  text-decoration: none;
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 18px;
  cursor: pointer;
}
a.nav-item:hover {
  text-decoration: none;
}
.mobile-menu-icon {
  display: none;
}

@media only screen and (max-width: 780px) {
  /*.mobile-menu-icon {*/
  .logo {
    display: flex;
    position: absolute;
    top: 15px;
    left: 35px;
  }
  .navbar {
    height: 200px;
  }
  .nav-links {
    display: none;
  }
  .nav-links-mobile {
    position: absolute;
    display: block;
    list-style: none;
    background-color: #2a6ab3;
    left: 0;
    top: 100px;
    transition: all 0.5s ease-out;
    width: 100%;
    z-index: 1;
  }
  .nav-item {
    color: #ffffff;
    text-align: center;
    padding: 32px;
    width: 100%;
    transition: all 0.5s ease;
  }
  .mobile-menu-icon {
    display: block;
    position: absolute;
    font-size: 30px;
    color: #ffffff;
    background-color: #2a6ab3;
    border: none;
    outline: none;
    top: 25px;
    right: 25px;
  }
}
